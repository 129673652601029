<template>
  <div style="height:100%">
    <v-tabs
      v-model="tab"
      vertical
      style="height:100%">
      <div class="no-print">
        <!-- <div v-for="tab, idx in tabs" :key="idx">
            <v-tab :href="`/system/${id}/${tab.name}`">
                <v-tooltip right>
                    <template v-slot:activator="{ on: tooltip, attrs }">
                        <v-icon left v-bind="attrs"
                        v-on="{ ...tooltip }">
                        mdi-{{ tab.icon }}
                        </v-icon>
                    </template>
                    <span>{{ $t(tab.name) }}</span>
                </v-tooltip>
            </v-tab>
        </div> -->

        <v-tab href="#dashboard">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-view-dashboard
                </v-icon>
            </template>
            <span>{{ $t('Back to Dashboard') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#image">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-image
                </v-icon>
            </template>
            <span>{{ $t('image') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#charts" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-gauge
                </v-icon>
            </template>
            <span>{{ $t('charts') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#config" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs}">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-cog
                </v-icon>
            </template>
            <span>{{ $t('configuration') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#settings" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-tune
                </v-icon>
            </template>
            <span>{{ $t('settings.name') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#notes" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-note
                </v-icon>
            </template>
            <span>{{ $t('notes') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#contacts" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-contacts
                </v-icon>
            </template>
            <span>{{ $t('contacts') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#report" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-clipboard-text
                </v-icon>
            </template>
            <span>{{ $t('report') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#check" v-if="currentUser.role < 3">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-clipboard-check-outline
                </v-icon>
            </template>
            <span>{{ $t('camera_check') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab href="#notifications" v-if="currentUser.role < 2">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-bell
                </v-icon>
            </template>
            <span>{{ $t('notifications') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab :disabled="inputData.version !== 1" href="#actions" v-if="currentUser.role < 2">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-ticket
                </v-icon>
            </template>
            <span>{{ $t('camera_actions') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab :disabled="inputData.version !== 1" href="#logs" v-if="currentUser.role < 2">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip, attrs }">
                <v-icon left v-bind="attrs"
                  v-on="{ ...tooltip }">
                  mdi-book-open-outline
                </v-icon>
            </template>
            <span>{{ $t('timelapse_log') }}</span>
          </v-tooltip>
        </v-tab>
      </div>

      <!-- GAUGES -->
      <v-tabs-items v-model="tab" class="overflow-hidden" style="height:100%">
        <v-tab-item value="dashboard" :to="{path: '/'}">
        </v-tab-item>

        <v-tab-item class="overflow-y-auto" style="padding-top:3rem; height:100%" value="charts" v-if="currentUser.role < 3">
            <Gauges :id="id"
                :version="inputData.version"
                :customGauges="abilities"
                :selectedTab="gaugeTab"></Gauges>
        </v-tab-item>

        <!-- SETTINGS -->
        <v-tab-item class="overflow-y-auto" style="height:100%" value="config" v-if="currentUser.role < 3">
            <InputMaskTabs type="system"
                :selectedId="id"
                :inputData="inputData"
                :preValidate="preValidate"
                v-on:edit-item-saved="itemSaved(arguments[0])"
                v-on:values-loaded="valuesLoaded(arguments[0])"
                :customSelects="{alertContacts}" />

            <v-dialog
              v-model="showStockChanged"
              max-width="1000">

              <StockChanges v-on:status-changed="stockHasChanged()"
                :systemId="inputData.id"
                :projectId="inputData.projectId"
                :changes="stockChanges"/>
            </v-dialog>
        </v-tab-item>

        <!-- CONFIG -->
        <v-tab-item class="overflow-y-auto" style="height:100%" value="settings" v-if="currentUser.role < 3">
            <InputMaskTabs type="config"
                :refId="id"
                :selectedId="id"
                :inputData="inputData && inputData.config ? inputData.config : {}"
                :hint="upToDate"
                v-on:edit-item-saved="itemSaved(arguments[0])" />
        </v-tab-item>

        <!-- NOTES -->
        <v-tab-item class="overflow-y-auto" style="height:100%" value="notes" v-if="currentUser.role < 3">
          <v-container v-if="loaded">
            <v-row>
              <v-col>
                <v-btn
                  @click.stop="showNewInputDialog('note', inputData.notes)">
                  <v-icon left>mdi-plus-circle</v-icon>{{ $t('New Note') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-for="systemNote in inputData.notes.toSorted((a,b) => b.updatedAt >= a.updatedAt)" :key="systemNote.id">
                <v-col style="margin-top:.5em;position:relative;">
                    <Note :type="'note'" :content="systemNote"></Note>
                </v-col>
            </v-row>

          </v-container>
        </v-tab-item>

        <!-- CONTACTS -->
        <v-tab-item class="overflow-y-auto" style="height:100%" value="contacts" v-if="currentUser.role < 3">
          <v-container v-if="loaded">

            <v-data-iterator
                :items="projectContacts"
                sort-by="updatedAt"
                :sort-desc="true"
                row
                hide-default-footer
                :no-data-text="$t('No Contacts found')"
                wrap>
                <template v-slot:item="props">
                  <v-row>
                      <v-col style="margin-top:.5em;position:relative;">
                          <Contact :dataBody="props.item"
                            :editable="false"></Contact>
                      </v-col>
                  </v-row>
                </template>
            </v-data-iterator>

          </v-container>
        </v-tab-item>

        <!-- IMAGE -->
        <v-tab-item class="overflow-y-auto" style="height:100%" value="image">
          <v-dialog
            v-model="showExif"
            width="500"
          >
            <v-card v-if="showExif">
              <v-card-title class="headline grey lighten-2">
                Exif Data
              </v-card-title>

              <v-container>
                <div v-for="(key, idx) in Object.keys(exif)"
                  :key="idx">
                  <v-row v-if="['undefined', 'MakerNote', 'UserComment'].indexOf(key) < 0">
                    <v-col cols="5">{{ key }}</v-col>
                    <v-col>{{ exif[key] }}</v-col>
                  </v-row>
                </div>
              </v-container>

            </v-card>
          </v-dialog>

          <v-toolbar v-if="currentUser.role < 2"
            dense
            height="72"
            flat>
            <div id="image-toolbar">
              <div v-for="(item, idx) in actionBtns"
                :key="`action-button-${idx}`"
                :style="{ display: activeActions.indexOf(item.name) >= 0 || timelapseActive ? 'inline-block' : 'none' }">

                <ActionButton
                  :id="idx"
                  :type="item.type"
                  :isActive="item.name === 'timelapse' ? !paused : false"
                  :status="item.hints"
                  :icons="item.icons"
                  :menuItemsPromise="item.menuItems"
                  :menuLabel="item.menuLabel"
                  :action="item.action"
                  :inactive="activeActions.indexOf(item.name) < 0 ? !timelapseActive : false"
                  :badgeText="item.badgeText"
                />
              </div>
            </div>
          </v-toolbar>

          <div :style="{ height: `calc( 100% - 80px)`, display: 'flex'}">
          <v-row class="mx-0 pt-3"
            style="width: 100%; position: relative; height: 100%;">

            <!--
                <v-navigation-drawer
              v-model="infoDrawer"
              class="my-5 elevation-2"
              hide-overlay
              absolute
              clipped
              temporary
              style="z-index:6;"
            >
            -->
            <v-col cols="2" style="max-width:250px">
              <v-subheader><v-icon class="mr-3">mdi-information-outline</v-icon> INFO</v-subheader>

              <v-divider></v-divider>

              <v-list flat>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                        <router-link v-if="inputData.project"
                            :to="`/project/${inputData.project.id}`">
                            {{ inputData.project.name }}
                        </router-link>
                        {{ inputData.camNo }}
                    </v-list-item-title>

                    <v-list-item-title class="caption grey--text">
                        {{ $t('Project - Camera No.') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ imageDatetime ? imageDatetime.format('YYYY-MM-DD HH:mm:ss') : '' }}
                    </v-list-item-title>

                    <v-list-item-title class="caption grey--text">
                        {{ $t('Creation Date') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                    <v-btn v-if="projectPath.value !== ''" icon small @click="copyContent('project-path')">
                        <v-list-item-icon class="mr-0">
                            <v-icon size="small">mdi-content-copy</v-icon>
                        </v-list-item-icon>
                    </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    v-on="on"
                                    id="project-path">{{ projectPath.value }}</span>
                            </template>
                            <span >{{ projectPath.value }}</span>
                        </v-tooltip>
                        <v-list-item-title class="text-caption" style="white-space: normal">{{ projectPath.hint }}</v-list-item-title>
                    </v-list-item-title>

                    <v-list-item-title class="caption grey--text">
                        {{ $t('project_path') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-for="(item, idx) in alternativeData"
                    :key="idx">
                  <v-list-item-content>
                    <div v-if="Array.isArray(item.text)">
                      <v-list-item-title v-for="(textRow, rowIdx) in item.text"
                        :key="rowIdx"
                        class="mb-1">
                        {{ textRow.title }}
                        <v-list-item-title class="text-caption" style="white-space: normal">{{ textRow.sub }}</v-list-item-title>
                      </v-list-item-title>
                    </div>

                    <v-list-item-title v-else>
                      {{ item.text }}
                    </v-list-item-title>

                    <v-list-item-title class="caption grey--text">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="inputData.config">
                  <v-list-item-content>
                    <v-list-item-title>
                        {{ inputData.config.latitude }}, {{ inputData.config.longitude }}
                      <v-btn small
                            icon
                            target="_blank"
                            :href="`https://maps.google.com/maps?q=${inputData.config.latitude},${inputData.config.longitude}&hl=es;z=14`">
                        <v-icon x-small>
                            mdi-open-in-new
                        </v-icon>
                    </v-btn>
                    </v-list-item-title>

                    <v-list-item-title class="caption grey--text">
                        {{ $t('Map') }}
                    </v-list-item-title>

                    <div style="height:150px;border:2px solid rgba(0,0,0,.5)">
                        <v-map style="z-index:2;" ref="mappy" :zoom=9 :center="[inputData.config.latitude, inputData.config.longitude]">
                            <!-- default tile provider https://{s}.tile.osm.org/{z}/{x}/{y}.png -->
                            <v-tilelayer url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"></v-tilelayer>
                        </v-map>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="10" class="pa-0" style="height: 100%;">
                <v-img
                  id="controlImage"
                  @mousedown="showCurrent = 0"
                  @mouseup="showCurrent = 1"
                  height="100%"
                  :src="showCurrent ? currentImageSource : refImageSource"
                  lazy-src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  contain
                ></v-img>
            </v-col>
        </v-row>
        </div>

        </v-tab-item>

        <!-- REPORT -->
        <v-tab-item style="height:100%" value="report" v-if="currentUser.role < 3">
          <Report
            :systemId="id"
            :image="currentImageSource"
            :data="Object.keys(inputData).length ? inputData : null"></Report>
        </v-tab-item>

        <!-- CHECK -->
        <v-tab-item style="height:100%" value="check" v-if="currentUser.role < 2">
            <Check
              :propId="id"
              :siteviewId="inputData.siteview_data ? parseInt(inputData.siteview_data.siteviewId) : null"></Check>
        </v-tab-item>

        <!-- NOTIFICATIONS -->
        <v-tab-item style="height:100%" value="notifications" v-if="currentUser.role < 2">
            <SystemNotifications
              :id="id"
              :notifications="inputData.notifications"
              :projectContacts="projectContacts"></SystemNotifications>
        </v-tab-item>

        <!-- ACTIONS -->
        <v-tab-item style="height:100%" value="actions" v-if="currentUser.role < 2">
            <Actions
              :propId="id"
              :siteviewId="inputData.siteview_data ? parseInt(inputData.siteview_data.siteviewId) : null"></Actions>
        </v-tab-item>

        <!-- Logs -->
        <v-tab-item style="height:100%" value="logs" v-if="currentUser.role < 2">
          <LogBook
            :systemId="id"></LogBook>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

  </div>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
} from 'vue2-leaflet'
import { mapActions } from 'vuex'
import { getToken } from '../_helpers/auth'
import { rest } from '../_helpers'
// import InputMaskTabs from '@/components/InputMaskTabs'
// import Note from '@/components/Note'
// import Contact from '@/components/Contact'
// import Charts from '@/components/Charts'

const axios = require('axios')
const moment = require('moment-timezone')

const Gauges = () => import(
    /* webpackChunkName: "system-gauges" */ '@/components/Gauges.vue'
)
const InputMaskTabs = () => import(
    /* webpackChunkName: "system-inputs" */ '@/components/InputMaskTabs.vue'
)
const Note = () => import(
    /* webpackChunkName: "inventory-details" */ '@/components/Note.vue'
)
const Contact = () => import(
    /* webpackChunkName: "system-contacts" */ '@/components/Contact.vue'
)
const Check = () => import(
    /* webpackChunkName: "system-check" */ '@/views/CameraCheck.vue'
)
const ActionButton = () => import(
    /* webpackChunkName: "system-actionbutton" */ '@/components/ActionButton.vue'
)
const StockChanges = () => import(
    /* webpackChunkName: "stock-changes" */ '@/components/StockChanges.vue'
)
const Report = () => import(
    /* webpackChunkName: "system-report" */ '@/components/Report.vue'
)
const Actions = () => import(
    /* webpackChunkName: "system-logs" */ '@/components/Actions.vue'
)
const LogBook = () => import(
    /* webpackChunkName: "system-logs" */ '@/components/Logs.vue'
)
const SystemNotifications = () => import(
    /* webpackChunkName: "system-check" */ '@/components/SystemNotifications.vue'
)
const EXIF = require('exif-js')
const cron = require('node-cron')
const misc = require('../_helpers/misc')

export default {
    data() {
        return {
            showExif: false,
            showStockChanged: false,
            stockChanges: {},
            oldStatusChanged: false,
            refImageSource: '',
            showCurrent: true,
            alertContacts: [],
            inputData: {},
            id: parseInt(this.$route.params.id, 10),
            exif: {},
            paused: false,
            refDate: Date.now(),
            actionBtns: [],
            systemActions: [],
            currentImageSource: '',
            configUsedAt: null,
            configTransferredAt: null,
            imageDatetime: null,
            loaded: false,
            infoDrawer: false,
            abilities: {
                timelapse: true,
                storage: false,
                wiper: false,
                power: false,
                sync: false,
                sensors: false,
            },
            alternativeData: [],
            logText: '',
            activeActions: ['info', 'exif', 'full_ref', 'full_img', 'set_ref'],
            statistics: {},
            currentValues: {},
            /* tabs: [
                {
                    icon: 'image',
                    name: 'image',
                },
            ], */
        }
    },

    components: {
        InputMaskTabs,
        Note,
        Contact,
        Gauges,
        Check,
        ActionButton,
        StockChanges,
        Report,
        Actions,
        LogBook,
        SystemNotifications,
        'v-map': LMap,
        'v-tilelayer': LTileLayer,
    },

    computed: {
        timelapseActive() {
            return this.inputData.enabled && this.inputData.config && this.inputData.config.com
        },

        currentUser() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY)).user
        },

        projectPath() {
            let projectPath = ''
            if (this.inputData.project) {
                if (this.inputData.project.projectDrive) {
                    projectPath += this.inputData.project.projectDrive === 'S' ? `${this.inputData.project.projectDrive}:/Zeitraffer/` : `${this.inputData.project.projectDrive}:/`
                }
                if (this.inputData.project.id && this.inputData.project.client.name && this.inputData.project.name && this.inputData.project.city) {
                    projectPath += `${this.inputData.project.id} - ${this.inputData.project.client.name} - ${this.inputData.project.name} - ${this.inputData.project.city}`
                }
            }
            return { value: projectPath, hint: projectPath === '' ? this.$t('incomplete_path_data') : '' }
        },

        tab: {
            set(view) {
                // this.$router.replace({ query: { ...this.$route.query, view } })
                if (view === 'image') {
                    this.getImage()
                }

                const newPath = `/system/${this.id}/${view}`
                this.$router.push(newPath)
            },
            get() {
                // return this.$route.query.view
                let view = this.$route.meta.selectedTab
                if (['health', 'weather'].indexOf(this.$route.meta.selectedSubTab) >= 0) {
                    view = 'charts'
                }
                return view || 'image'
            },
        },

        gaugeTab() {
            return this.$route.meta.selectedSubTab
        },

        projectContacts() {
            let projectContacts = []
            if (Object.hasOwnProperty.call(this.inputData, 'project')
                && (this.inputData.project.contacts
                && this.inputData.project.contacts.length)) {
                projectContacts = this.inputData.project.contacts
            }
            return projectContacts
        },

        upToDate() {
            const systemId = this.id
            let data = {
                icon: 'mdi-do-not-disturb',
                text: 'Camera out of date',
                color: 'error',
                link() {
                    rest.updateConfig(systemId)
                        .catch(err => { })
                },
            }

            if (this.configTransferredAt && this.configUsedAt) {
                const updated = this.configTransferredAt.isBefore(this.configUsedAt)
                if (updated) {
                    data = {
                        icon: 'mdi-check-circle-outline',
                        text: 'Usage of current Settings',
                        color: 'success',
                        link() { return true },
                    }
                } else {
                    data.text = `Camera out of date ${misc.formatTime(this.configTransferredAt.diff(this.configUsedAt, 'seconds'))}
                        (${this.configUsedAt.format('YYYY-MM-DD HH:mm:ss')})`
                }
            }

            return data
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            showInputDialog: 'input/showDialog',
            setInputDialog: 'input/setInputDialog',
            setNew: 'input/setNew',
            setTitle: 'page/setTitle',
            showSnackbar: 'page/showSnackbar',
            setSnackbar: 'page/setSnackbar',
            setConfirmDialogData: 'page/setConfirmDialogData',
        }),

        copyContent(copyData) {
            const copyText = document.getElementById(copyData)
            navigator.clipboard.writeText(copyText.textContent)

            this.setSnackbar({
                type: 'success',
                text: `${this.$t('Link copied!')}`,
            })
            this.showSnackbar(true)
        },

        stockHasChanged() {
            this.showStockChanged = false
            this.oldStatusChanged = true
        },

        preValidate(changed) {
            return new Promise((resolve, reject) => {
                const stockChanges = {}
                const keys = ['computer', 'camera', 'lens', 'router', 'switch', 'sim1', 'sim2', 'simSwitch', 'housing', 'control', 'dryer', 'batteryData']
                keys.forEach(key => {
                    if (changed.enabled && changed.enabled.to === 0) {
                        const item = this.currentValues[key]
                        if (item && (!Array.isArray(item) || item.length)) {
                            stockChanges[this.inputData[key]] = { type: key, from: item }
                        }
                    } else if (Object.hasOwnProperty.call(changed, key) && changed[key].from) {
                        if (Array.isArray(changed[key].from)) {
                            if (changed[key].from.length) {
                                const oldIds = changed[key].from.map(x => x.id)
                                const newIds = changed[key].to.map(x => x.id)
                                const removedIds = oldIds.filter(x => !newIds.includes(x))

                                const items = changed[key].from
                                items.forEach(item => {
                                    if (removedIds.includes(item.id)) {
                                        stockChanges[item.id] = { type: changed[key].type, from: item }
                                    }
                                })
                            }
                        } else {
                            stockChanges[changed[key].from.id] = changed[key]
                        }
                    }
                })

                this.stockChanges = stockChanges

                if (Object.keys(this.stockChanges).length) {
                    this.showStockChanged = true
                } else {
                    this.oldStatusChanged = true
                }

                const timer = ms => new Promise(res => { setTimeout(res, ms) })

                const wait = async () => {
                    while (!this.oldStatusChanged) {
                        await timer(1000)
                    }

                    this.oldStatusChanged = false

                    resolve(true)
                }

                wait()
            })
        },

        getReceivers() {
            let payload = {
                attributes: [['displayname', 'name'], ['email', 'mails'], 'id'],
                includes: [],
            }

            let receivers = []
            if (this.currentUser.role > 1) {
                return Promise.resolve(receivers)
            }
            return rest.getRows('user', payload)
                .then(result => {
                    receivers = result
                    payload = {
                        attributes: [],
                        where: { id: this.inputData.projectId },
                        includes: [
                            {
                                type: 'contact',
                                sort: [['surname', 1]],
                                where: { mails: { $ne: null } },
                                attributes: ['id', 'name', 'surname', 'mails'],
                            },
                        ],
                    }

                    return rest.getRows('project', payload)
                })
                .then(result => {
                    if (result && result.length > 0 && result[0].contacts) {
                        const { contacts } = result[0]
                        contacts.forEach(rec => {
                            rec.name = `${rec.name} ${rec.surname}`
                            receivers.push(rec)
                        })
                    }

                    return receivers
                })
        },

        receiversFilter(item, queryText) {
            const textOne = item.name.toLowerCase()
            const textTwo = item.mail.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1
                || textTwo.indexOf(searchText) > -1
        },

        downloadItem(url, mime) {
            const label = `${this.inputData.id}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.jpg`

            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: mime ? mime : 'image/jpeg' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = label
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        },

        getImage() {
            const currentImage = new Image()
            currentImage.crossOrigin = 'Anonymous'
            currentImage.onload = () => {
                const obj = this
                EXIF.getData(currentImage, function () {
                    const exif = EXIF.getAllTags(this)
                    obj.exif = exif

                    let tz = (obj.inputData && obj.inputData.config) ? obj.inputData.config.timezone : 'utc'
                    if (obj.inputData.housingData && obj.inputData.housingData.make === 'Enlaps') {
                        tz = 'utc'
                    }
                    const exifDate = moment.tz(exif.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss', tz)

                    if (exifDate.isValid()) {
                        obj.imageDatetime = exifDate.tz('Europe/Berlin') // USER_TIMEZONE
                    } else {
                        axios.get(currentImage.src)
                            .then(resp => {
                                obj.imageDatetime = moment(resp.headers['last-modified'])
                            })
                    }
                })
            }

            // this.currentImage.src = 'https://webcam.zeitraffer.org/ftp/' + this.inputData.currentImage + '?ver='+Date.now()
            const now = Date.now()
            currentImage.src = `${process.env.VUE_APP_REST_DOMAIN}/static/images/${this.id}.jpg?token=${getToken()}&ver=${now}`
            this.currentImageSource = `${process.env.VUE_APP_REST_DOMAIN}/static/images/${this.id}.jpg?token=${getToken()}&ver=${now}`
        },

        getData() {
            return rest.getRow('system', this.id)
                .then(item => {
                    if (item) {
                        return rest.getRow('project', item.project.id)
                            .then(project => {
                                this.abilities = {
                                    wiper: !item.computer ? false : !!item.wiper,
                                    power: (!item.routerData || item.routerData.make === 'Teltonika') ? false : !!item.router,
                                    sync: !!item.computer,
                                    storage: !!item.computer,
                                    sensors: !!item.computer,
                                }

                                this.inputData = item
                                this.inputData.project.contacts = project.contacts
                                this.paused = item.paused

                                // this.refImage.src = 'https://webcam.zeitraffer.org/ftp/refimages/' + this.inputData.refImage
                                this.refImageSource = `${process.env.VUE_APP_REST_DOMAIN}/static/images/refs/${this.id}.jpg?token=${getToken()}&ver=${this.refDate}`

                                this.getImage()

                                this.loaded = true

                                return true
                            })
                    }

                    this.$router.replace('/404')
                    return false
                })
        },

        setInfoData() {
            let info
            const syncText = []

            if (false) { // !(this.inputData.config && this.inputData.config.com)) {
                rest.getRow('systemInfo', this.id)
                    .then(systemInfo => {
                        if (systemInfo) {
                            info = systemInfo
                            if (info.syncData) {
                                const syncData = JSON.parse(info.syncData)

                                Object.values(syncData).forEach(folder => {
                                    folder.data.forEach(device => {
                                        syncText.push({
                                            title: `${device.completion.toFixed(2)} %`,
                                            sub: `${folder.name} ⇒ ${device.deviceName}`,
                                        })
                                    })
                                })
                            }

                            this.alternativeData = [
                                { name: 'Date', value: info.date, text: `${moment(info.date).format('YYYY-MM-DD')}` },
                                { name: 'Daily Picture Count / Estimated', value: info.pictureCount, text: `${info.pictureCount} / ${info.estimatedCount}` },
                                { name: 'Average Picture Size', value: info.averagePictureSize, text: misc.formatBytes(info.averagePictureSize) },
                                { name: 'Water Level', value: info.waterLevel, text: `${info.waterLevel} ml` },
                                { name: 'Sync Status', value: null, text: syncText },
                            ]
                        }
                    })
            } else {
                info = this.statistics
                if (info.syncData) {
                    const { syncData } = info

                    Object.values(syncData).forEach(folder => {
                        folder.data.forEach(device => {
                            syncText.push({
                                title: `${device.completion.toFixed(2)} %`,
                                sub: `${folder.name} ⇒ ${device.deviceName}`,
                            })
                        })
                    })
                }

                const weeksLeft = info.tankLevel / (4 * (this.inputData.config ? this.inputData.config.wiperPerUsage : 0))
                const tankEmptyAt = moment.utc().add(weeksLeft, 'weeks').format('YYYY-MM-DD')

                this.alternativeData = [
                    // { name: 'Date', value: info.date, text: `${moment(info.date).format('YYYY-MM-DD')}` },
                    { name: this.$t('Daily Picture Count / Estimated'), value: info.pictureCount, text: `${info.pictureCount ? info.pictureCount : '-'} / ${info.estimatedCount ? info.estimatedCount : '-'}` },
                    { name: this.$t('Average Picture Size'), value: info.averageSize, text: info.averageSize ? misc.formatBytes(info.averageSize) : '- Bytes' },
                    { name: this.$t('Water Level'), value: info.tankLevel, text: [{ title: `${info.tankLevel ? info.tankLevel : '-'} ml`, sub: `${this.$t('empty_at')}: ${tankEmptyAt}` }] },
                    { name: this.$t('Sync Status'), value: null, text: syncText },
                ]
            }
        },

        showNewInputDialog(type, updateList) {
            let referencefId
            const referenceType = 1
            let data = {}

            switch (type) {
            case 'note':
                referencefId = this.inputData.id
                data = { refId: referencefId, refType: referenceType }
                break
            case 'contact':
                referencefId = this.inputData.projectId
                break
            default:
                break
            }

            this.setInputDialog({
                type,
                confirm: false,
                title: type.charAt(0).toUpperCase() + type.slice(1),
                data,
                modified: (idx, item) => {
                    if (Object.keys(item).length) {
                        updateList.push(item)
                    }
                    this.showInputDialog(false)
                },
            })
            this.showInputDialog(true)
        },

        itemSaved(item) {
            if (item.type === 'system') {
                this.inputData = item.data
                this.refreshData = true
            } else if (item.type === 'config') {
                this.inputData.config = item.data
            } else if (item.type === 'check') {
                this.checkPanel = null
            }
        },

        valuesLoaded(values) {
            this.currentValues = values
        },

        getStats() {
            return rest.getRow('statistics', this.id)
                .then(result => {
                    if (result) {
                        this.statistics = result

                        if (this.inputData.config && this.inputData.config.com) {
                            this.statistics.tankLevel = this.inputData.config.tankSize - (this.statistics.wiperCount * this.inputData.config.wiperPerUsage)
                        }

                        if (result.configUsedAt) {
                            this.configUsedAt = moment(result.configUsedAt)
                        }
                        if (result.configTransferredAt) {
                            this.configTransferredAt = moment(result.configTransferredAt)
                        }
                    }

                    return rest.getRow('sync', this.id)
                })
                .then(sync => {
                    this.statistics.syncData = sync ? sync.data : null
                })
        },
    },

    created() {
        this.updateCron = cron.schedule('*/10 * * * *', () => {
            this.getImage()
        })
    },

    mounted() {
        this.showSpinner(true)

        this.getData()
            .then(resultData => {
                if (resultData) {
                    this.getStats()
                        .then(() => {
                            this.setInfoData()
                        })

                    this.actionBtns = [
                        {
                            name: 'timelapse',
                            action: () => rest.pause(this.id, !this.paused)
                                .then(result => {
                                    if (result.success) {
                                        this.paused = !this.paused
                                    }
                                    result.active = this.paused
                                    return result
                                }),
                            icons: ['stop', 'play'],
                            hints: ['Stop', 'Start'],
                            type: 'toggle',
                        },
                        /* {
                            name: 'info',
                            action: () => new Promise((res, rej) => {
                                this.infoDrawer = !this.infoDrawer
                                res(null)
                            }),
                            icons: ['information-outline'],
                            hints: ['Show Info'],
                        }, */
                        {
                            action: () => rest.capture(this.id)
                                .then(result => {
                                    if (result.success) {
                                        this.currentImageSource = `${process.env.VUE_APP_REST_DOMAIN}/static/images/${this.id}.jpg?token=${getToken()}&ver=${Date.now()}`
                                    }
                                    return result
                                }),
                            type: 'process',
                            icons: ['camera-wireless-outline', 'camera-wireless'],
                            hints: ['Capture', 'Capturing'],
                        },
                        {
                            action: () => Promise.resolve(null),
                            menuItems: this.getReceivers(),
                            menuLabel: 'Receivers',
                            icons: ['email'],
                            hints: ['Send E-Mail'],
                        },
                        {
                            action: () => new Promise((res, rej) => {
                                this.downloadItem(this.currentImageSource)
                                res(null)
                            }),
                            icons: ['cloud-download'],
                            hints: ['Download'],
                        },
                    ]

                    if (this.inputData.wiper) {
                        this.actionBtns.push({
                            name: 'wiper',
                            action: () => rest.doWipe(this.id),
                            type: 'process',
                            icons: ['wiper-wash', 'wiper'],
                            hints: ['Wiper', 'Wiping'],
                        })
                    }

                    this.actionBtns = [
                        ...this.actionBtns,
                        ...[
                            {
                                action: () => rest.newSession(this.id),
                                icons: ['folder-plus'],
                                hints: ['New Session Folder'],
                            },
                            {
                                name: 'set_ref',
                                badgeText: 'Set Ref',
                                action: () => this.setConfirmDialogData({
                                    title: this.$t('check.set_reference'),
                                    text: `${this.$t('ref_question')}
                                        ${this.$t('camera.name')}
                                        ${this.inputData.camNo}
                                        ${(this.inputData.computerData && this.inputData.computerData.computer) ? `(${this.inputData.computerData.computer.name})` : ''}
                                        ${this.$t('of_project')}
                                        ${this.inputData.project.name}`,
                                    func: () => rest.setRef(this.id)
                                        .then(result => {
                                            if (result && result.success) {
                                                this.refDate = Date.now()
                                            }
                                            return result
                                        }),
                                }),
                                icons: ['image-multiple'],
                                hints: ['Set Reference Image'],
                            },
                            {
                                name: 'full_img',
                                badgeText: 'Full',
                                action: () => new Promise((res, rej) => {
                                    window.open(this.currentImageSource, '_blank')
                                    res(null)
                                }),
                                icons: ['file-image'],
                                hints: ['Full Image'],
                            },
                            {
                                name: 'full_ref',
                                badgeText: 'Ref',
                                action: () => new Promise((res, rej) => {
                                    window.open(this.refImageSource, '_blank')
                                    res(null)
                                }),
                                icons: ['file-image-outline'],
                                hints: ['Full Reference Image'],
                            },
                            {
                                name: 'exif',
                                badgeText: 'Exif',
                                action: () => new Promise((res, rej) => {
                                    this.showExif = !this.showExif
                                    res(null)
                                }),
                                icons: ['image-search'],
                                hints: ['Show Exif'],
                            },
                        ],
                    ]
                }
            })
            .finally(() => {
                this.infoDrawer = true
                setTimeout(() => { this.infoDrawer = false }, 2000)
                this.showSpinner(false)

                const subTitle = `${this.inputData.project ? this.inputData.project.name : ''} ${this.inputData.camNo}`
                const titles = {
                    name: `${(this.inputData.computerData && this.inputData.computerData.computer) ? `${this.inputData.computerData.computer.name} - ${subTitle}` : subTitle}`,
                    sub: subTitle,
                    pId: this.inputData.project ? this.inputData.project.id : null,
                    id: this.id,
                }

                document.title = `${document.title} | ${titles.sub}`
                this.setTitle(titles)
            })
    },
}
</script>


<style scoped lang="stylus">
  #image-toolbar
    width 100%
    display flex
    justify-content space-around
    overflow-x auto
</style>
